import React from 'react'
import { graphql } from 'gatsby'

import { Layout, Text } from '../../'

export default ({ data }) => {
    const { hero } = data
    const { important } = hero

    return (
        <Layout pageTitle={"Важно знать"}>
            <Text content={important.json} />
        </Layout>
    )
}

export const query = graphql`
  query($id: String!) {
    hero: contentfulHero(id: {eq: $id}) {
      important {
        json
      }
    }
  }
`
